.world {
    display: flex !important;
    flex-wrap: nowrap;
    align-content: stretch;
    justify-content: space-evenly;
    align-items: baseline;
  }

  .nav-link {
    cursor: pointer;
  }