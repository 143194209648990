
.boxes {
  padding: 10px;
}

.boxes-title {
  display: flex;
  align-items: center;
  gap: 10px;
  /* background: rgb(0 0 0 / 50%); */
  border-radius: 10px 10px 0 0;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.playraven {
  padding-top: 10px;
  width: 620px;
  height: 374px;
}

.world {
  display: flex;
  flex-wrap: nowrap;
  align-content: stretch;
  justify-content: space-evenly;
  align-items: baseline;
}